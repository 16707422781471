import React from 'react';
import logo from '../images/ODDSLogo.png';

function Header() {
	return (
		<div className="header">
			<div className="logo-container">
				<img className="logo" src={logo} /> <font className="logo-text-right">Long Call Option Index (v1.1 beta)</font>
				<h1 className="logo-text">Analytics</h1>
			</div>
		</div>
	);
}

export default Header;
