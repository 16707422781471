import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
function Graph(state) {
	try {
		state.HRHChart = Highcharts.chart('chart', {
			backgroundColor: 'transparent',
			fill: 'transparent',
			title: {
				text: 'ODDS® Long Call Option Index',
				style: {
					color: '#001F51',
					fontSize: '1.8em',
					fontWeight: 'normal',
				},
			},
			/* chart: {
				borderRadius: 15,
			}, */
			tooltip: {
				xDateFormat: '%A, %B %d, %Y',
				shared: true,
			},
			yAxis: {
				title: {
					text: 'Index Value',
				},
			},
			xAxis: {
				type: 'datetime',
				labels: {
					formatter: function () {
						return Highcharts.dateFormat('%b %d %Y', this.value);
					},
				},
				accessibility: {
					rangeDescription: 'week start to week end',
				},
			},
			legend: {
				layout: 'horizontal',
				align: 'right',
				verticalAlign: 'top',
			},
			plotOptions: {
				series: {
					label: {
						connectorAllowed: false,
					},
				},
			},
			series: [
				{
					name: 'Long Call Option Index',
					color: '#001F51',
					data: state.optionIndex,
				},
				{
					name: 'Underlier Total Return Index',
					color: '#00A82C',
					data: state.totalReturnIndex,
				},
			],
			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 500,
						},
						chartOptions: {
							legend: {
								layout: 'horizontal',
								align: 'center',
								verticalAlign: 'bottom',
							},
						},
					},
				],
			},
			credits: {
				enabled: false,
			},
		});
	} catch (e) {
		console.log("Couldn't update graph:", this.state);
	}
}

class LineGraph extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			totalReturnIndexData: props.data?.totalReturnIndex,
			optionIndexData: props.data?.optionIndex,
			totalReturnIndex: props.data?.totalReturnIndex,
			optionIndex: props.data?.optionIndex,
			dates: props.data?.dates,
			HRHChart: undefined,
			startDate: props.data?.startDate,
			endDate: props.data?.endDate,
		};
	}

	formatData(dates, data) {
		let tempData = [];
		for (let i = 0; i < dates.length; i++) {
			//Convert date to milliseconds
			let milli = new Date(dates[i]).getTime();
			tempData.push([milli, data[i]]);
		}
		return tempData;
	}

	componentDidMount() {
		Graph(this.state);
	}

	componentDidUpdate() {
		if (
			this.state.totalReturnIndexData !== this.props.data.totalReturnIndex ||
			this.state.optionIndexData !== this.props.data.optionIndex ||
			this.state.dates !== this.props.data.dates ||
			this.state.startDate !== this.props.data.startDate ||
			this.state.endDate !== this.props.data.endDate
		) {
			let totIndex = this.formatData(
				this.props.data.dates,
				this.props.data.totalReturnIndex
			);
			let optIndex = this.formatData(
				this.props.data.dates,
				this.props.data.optionIndex
			);
			this.setState({
				totalReturnIndexData: this.props.data.totalReturnIndex,
				optionIndexData: this.props.data.optionIndex,
				totalReturnIndex: totIndex,
				optionIndex: optIndex,
				dates: this.props.data.dates,
				startDate: this.props.data.startDate,
				endDate: this.props.data.endDate,
			});
		}
		Graph(this.state);
	}

	render() {
		return (
			<div className='probabilities'>
				<div id='chart' className='chart'>
					<HighchartsReact
						highcharts={this.state.HRHChart}
						style={{ width: '100%', backgroundColor : 'transparent', fill: 'transparent' }}
					/>
				</div>
			</div>
		);
	}
}

export default LineGraph;
