//This is Blue Gang's file
import React from 'react';
import 'date-fns';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import DatePicker from 'react-datetime';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

class ControlPanel extends React.Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeT = this.handleChangeT.bind(this);

		let today = new Date(new Date().setDate(new Date().getDate()-2))
			.toLocaleDateString('en-US');
		

		let lastMonthDate = new Date('2022-02-19');
		let lastMonth = lastMonthDate.toLocaleDateString('en-US');

		this.state = {
			ticker: 'SPY',
			showAlert: false,
			alertMessage: '',
			startDate: props.range.startDate,
			endDate: props.range.endDate,
			expires: [],
			startPeriod: lastMonth,
			endPeriod: today,
			expDates: [],
			delta: .50,
			calls: 1
		};
		this.tickers = ['AAPL','AMD','AMZN','AVGO','BA','BAC','C','CB','CMI','CVS','DG','DIS','FB','GOOG','GS','GWW','INTC','JBLU','JNJ','JPM','MMC','MS','MU','PG','QCOM','SBUX','TGT','TSLA','WMT','DIA','IWM','QQQ','SPY'];
	}

	componentDidMount() {
		this.getExpDates();
		this.getTickerAndDates();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.range.startDate !== this.props.range.startDate ||
			prevProps.range.endDate !== this.props.range.endDate
		) {
			this.setState({
				startDate: this.props.range.startDate,
				endDate: this.props.range.endDate,
			});
		}
	}

	handleEndDateChange = (date) => {
		let newDate = new Date(date).toLocaleDateString('en-US');
		this.setState({ endPeriod: newDate });
		this.forceUpdate();
	};

	handleDeltaChange = (newDelta) => {
		this.setState({ delta: newDelta.value });
		this.forceUpdate();
	};

	handleCallChange = (newCalls) => {
		this.setState({ calls: newCalls.value });
		this.forceUpdate();
	};

	handleStartDateChange = (date) => {
		let newDate = new Date(date).toLocaleDateString('en-US');
		this.setState({ startPeriod: newDate });
		this.forceUpdate();
	};

	handleChange(e) {
		const { id, value } = e.target;
		this.setState((prevState) => ({
			...prevState,
			[id]: value,
		}));
	}
	handleChangeT(e) {
		const { id, value } = e.target;
		this.setState(prevState => ({
			...prevState,
			[id]: value
		}));
		this.state.ticker=value;
		this.fetchInitialData();
	}

	fetchExpDates(startPeriod, endPeriod) {
		fetch(`https://hrh.oddsonline.com:9100/api/expDates?startPeriod=${startPeriod}&endPeriod=${endPeriod}`)
			.then((res) => res.text())
			.then((res) => this.setState({ expDates: res }));
	}

	getExpDates() {
		let startPeriod = '1998-01-16';
		let endPeriod = new Date(new Date().setDate(new Date().getDate()-2))
			.toLocaleDateString('en-GB')
			.split('/')
			.reverse()
			.join('-');
		this.fetchExpDates(startPeriod, endPeriod);
	}

	getTickerAndDates = (event) => {
		let ticker = this.state.ticker;
		let startPeriod = new Date(this.state.startPeriod)
			.toLocaleDateString('en-GB')
			.split('/')
			.reverse()
			.join('-');
		let endPeriod = new Date(this.state.endPeriod)
			.toLocaleDateString('en-GB')
			.split('/')
			.reverse()
			.join('-');
		this.getTicker(ticker, startPeriod, endPeriod, this.state.delta, this.state.calls);
	};

	getTicker(ticker, startPeriod, endPeriod, delta, calls) {
		fetch(
			`https://hrh.oddsonline.com:9100/api/main?ticker=${ticker}&startPeriod=${startPeriod}&endPeriod=${endPeriod}&delta=${delta}&calls=${calls}`
		)
			.then((res) => res.text())
			.then((res) =>
				this.setState({ results: res }, () => {
					try {
						const resultsObj = JSON.parse(this.state.results);
						const issuerName = resultsObj[3];
						this.props.renderNewAnalysis(
							ticker,
							startPeriod,
							endPeriod,
							resultsObj[2][resultsObj[2].length - 1], //Latest Option Index
							resultsObj[1][resultsObj[1].length - 1], //Latest Total Return Index
							resultsObj,
							issuerName
						);
					} catch (e) {
						console.log(
							'Error returning data from Control Panel',
							this.state.results,
							e
						);
					}
				})
			)
			.catch((err) => err);
	}

	// disable the list of custom dates
	enableStartPeriodDates = (current) => {
		let date = new Date(current);
		let today = new Date();
		let endDate = new Date(this.state.endPeriod);
		if (date > today || date > endDate) {
			return false;
		} else {
			let enabledDates = this.state.expDates;
			return enabledDates.includes(current.format('YYYY-MM-DD'));
		}
	};

	disableEndDates = (current) => {
		let date = new Date(current);
		let today = new Date();
		if (date > today) {
			return false;
		} else return true;
	};

	setAlert(message) {
		this.setState({ showAlert: true, alertMessage: message });
	}

	render() {

		const optionsDelta = [
			{ label: '.10', value: .10 },
			{ label: '.15', value: .15 },
			{ label: '.20', value: .20 },
			{ label: '.25', value: .25 },
			{ label: '.30', value: .30 },
			{ label: '.35', value: .35 },
			{ label: '.40', value: .40 },
			{ label: '.45', value: .45 },
			{ label: '.50', value: .50 },
			{ label: '.55', value: .55 },
			{ label: '.60', value: .60 },
			{ label: '.65', value: .65 },
			{ label: '.70', value: .70 },
			{ label: '.75', value: .75 },
			{ label: '.80', value: .80 }
		  ];

		  const optionsCalls = [
			{ label: '1', value: 1 },
			{ label: '2', value: 2 },
			{ label: '3', value: 3 },
			{ label: '4', value: 4 }
		  ];
		  

		return (
			<>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={this.state.showAlert}
					onClose={() => this.setState({ showAlert: false })}
					message={this.state.alertMessage}
					key={'topcenter'}
					autoHideDuration={3000}
				/>
				<div className='control-panel'>
					<h2 className='control-title'>Control Panel</h2>
					<div className='formField'>
						<label htmlFor='ticker'>Ticker</label>
						<input
							className='control-input'
							id='ticker'
							label='Ticker'
							type='text'
							placeholder='ABC'
							value={this.state.ticker}
							onChange={this.handleChange}
							// onBlur={this.fetchExp}
							style={{ textTransform: 'uppercase' }}
						/>
						{/* <select className="control-input" id="ticker" label="Ticker" onChange={this.handleChangeT} value={this.state.ticker}>
							{/* <option value="SPY">SPY</option>
							<option value="ETH">ETH</option>
							<option value="DOGE">DOGE</option>  */}
						{/* 	{this.tickers.map(ticker => (
								<option key={ticker} value={ticker}>
									{ticker}
								</option>
							))}
						</select> */}
					</div>
					<div className='formField'>
						<label htmlFor='startPeriod'>Start Date</label>
						<DatePicker
							className='control-input'
							type='text'
							timeFormat={false}
							dateFormat='M/DD/YYYY'
							value={this.state.startPeriod}
							selected={this.state.startPeriod}
							onChange={this.handleStartDateChange}
							isValidDate={this.enableStartPeriodDates}
							closeOnSelect={true}
						/>
					</div>
					<div className='formField'>
						<label htmlFor='endPeriod'>End Date</label>
						<DatePicker
							className='control-input'
							type='text'
							timeFormat={false}
							dateFormat='M/DD/YYYY'
							value={this.state.endPeriod}
							selected={this.state.endPeriod}
							onChange={this.handleEndDateChange}
							isValidDate={this.disableEndDates}
							closeOnSelect={true}
						/>
					</div>
					<div className='formField'>
						<label htmlFor='delta'>Delta</label>
						<Dropdown
							controlClassName='Dropdown2-control'
							className='control-input'
							placeholder=".50"
							onChange={this.handleDeltaChange}
							options={optionsDelta}
							/>
					</div>
					<div className='formField'>
						<label htmlFor='calls'>Calls to Buy</label>
						<Dropdown
							controlClassName='Dropdown2-control'
							className='control-input'
							placeholder="1"
							onChange={this.handleCallChange}
							options={optionsCalls}
						/>
					</div>
					<Button
						variant='outlined'
						className='control-button'
						onClick={this.getTickerAndDates}
						style={{
							color: 'black',
							borderColor: 'black',
							height: '2em',
							marginTop: '1em',
						}}
					>
						Submit
					</Button>
					<div className='download-button'></div>
				</div>
			</>
		);
	}
}

export default ControlPanel;
