import React from 'react';

function Footer() {
	return(
        <div className="footer">
            <div id="footer-chart" className="footer-chart" style={{ paddingBottom: '15px',paddingRight: '15px', textAlign: 'right', fontSize: '0.6em'}}>
            NOTICE TO BETA USERS: Due to extremely wide bid-ask spreads in illiquid options, aberrations in the index may occur. We ask that you report any unusual index behavior as we consider screening criteria to filter out low volume situations.<br />ODDS® Analytics uses data sourced from Cboe Hanweck and ICE Data Services.<br />ODDS® is a registered trademark of Don Fishback.
            </div>
        </div>
	);
}

export default Footer;