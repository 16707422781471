import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import AnalysisPanel from './components/AnalysisPanel';
import ControlPanel from './components/ControlPanel';
import React from 'react';
import LineGraph from './components/LineGraph';
import 'react-datetime/css/react-datetime.css';

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: {
				totalReturnIndex: [],
				optionIndex: [],
				startDate: null,
				endDate: null,
			},
			statistics: {
				ticker: null,
				currentPrice: null,
				targetPrice: null,
				tradingDays: null,
				probBelow: null,
				probAbove: null,
				count: null,
				std: null,
				mean: null,
			},
			delta: {
				ticker: null,
				expDate: null,
				lastDate: null,
				graphInfo: { delta: [], theoretical: [], xAxis: [] },
			},
			expires: {},
			priceHistory: {},
			range: {
				startDate: null,
				endDate: null,
			},
		};
		this.analysisPanelData = this.state.delta;
		this.lineGraphData = this.state.data;
	}

	//Given a new set of data for teh AnalysisPanel to render, re-render the page.
	renderNewAnalysis(
		ticker,
		startDate,
		endDate,
		optionIndex,
		returnIndex,
		data,
		issuerName
	) {
		const options = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		};
		let sDate = new Date(String(startDate));
		sDate.setDate(sDate.getDate() + 1);
		let eDate = new Date(String(endDate));
		eDate.setDate(eDate.getDate() + 1);
		let sDateString = sDate.toLocaleDateString('en-US', options);
		let eDateString = eDate.toLocaleDateString('en-US', options);

		this.analysisPanelData = {
			ticker: ticker,
			issuerName: issuerName,
			startDate: sDateString,
			endDate: eDateString,
			optionIndex: optionIndex,
			returnIndex: returnIndex,
		};

		this.lineGraphData = {
			totalReturnIndex: data[2],
			optionIndex: data[1],
			dates: data[0],
			startDate: startDate,
			endDate: endDate,
		};
		//console.log(data);
		this.forceUpdate();
	}

	render() {
		return (
			<div className='wrapper' style={{ marginBottom: '10px' }}>
				<Header />
				<div className='main'>
					<div className='content-container'>
						<div xs={12} className='hist-and-cont'>
							<ControlPanel
								renderNewAnalysis={this.renderNewAnalysis.bind(this)}
								setAlert={this.setAlert}
								range={this.state.range}
							/>
							<LineGraph data={this.lineGraphData} />
							<Footer />
						</div>
						<AnalysisPanel delta={this.analysisPanelData} />
					</div>
				</div>
				<br></br>
			</div>
		);
	}
}

export default App;
