import React from 'react';
import Button from '@material-ui/core/Button';

class AnalysisPanel extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			ticker: props.delta?.ticker,
			issuerName: props.delta?.issuerName,
			currentReturnIndex: props.delta?.currentReturnIndex,
			currentOptionIndex: props.delta?.currentOptionIndex,
			currentIssuer: props.delta?.issuer,
			startDate: props.delta?.startDate,
			endDate: props.delta?.endDate,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.state.ticker !== this.props.delta.ticker ||
			this.state.currentReturnIndex !== this.props.delta.returnIndex ||
			this.state.currentOptionIndex !== this.props.delta.optionIndex ||
			this.state.startDate !== this.props.delta.startDate ||
			this.state.endDate !== this.props.delta.endDate
		) {
			this.setState({
				ticker: this.props.delta.ticker,
				issuerName: this.props.delta.issuerName,
				currentReturnIndex: this.props.delta.returnIndex,
				currentOptionIndex: this.props.delta.optionIndex,
				currentIssuer: this.props.delta.issuer,
				startDate: this.props.delta.startDate,
				endDate: this.props.delta.endDate,
			});
		}
	}

	downloadSpreadsheet() {
		fetch('https://hrh.oddsonline.com:9100/api/file?filename=spreadsheet.csv')
			.then((data) => {
				window.open('https://hrh.oddsonline.com:9100/api/file?filename=spreadsheet.csv');
			})
			.catch((err) => err);
	}

	render() {
		return (
			<div className='analysis-panel'>
				<div className='analysis-container'>
					<h2 className='analysis-title'>
						<u>Underlier Data</u>
					</h2>
					<div className='flex-container'>
						<div className='analysis-data-container'>
							<div className='analysis-data-left'>Ticker:</div>
							<div className='analysis-data-right'>
								{this.state.ticker == null
									? ''
									: this.state.ticker.toUpperCase()}
							</div>
						</div>
						<div className='analysis-data-container'>
						<div className='analysis-data-left'>Issuer Name:</div>
							<div className='analysis-data-right'>
								{this.state.issuerName}
							</div>
						</div>
						<div className='analysis-data-container'>
							<div className='analysis-data-left'>Current Option Index:</div>
							<div className='analysis-data-right'>
								{this.state.currentReturnIndex}
							</div>
						</div>
						<div className='analysis-data-container'>
							<div className='analysis-data-left'>Current Return Index:</div>
							<div className='analysis-data-right'>
								{this.state.currentOptionIndex}
							</div>
						</div>
						<div className='analysis-data-container'>
							<div className='analysis-data-left'>Start Date:</div>
							<div className='analysis-data-right'>{this.state.startDate}</div>
						</div>
						<div className='analysis-data-container'>
							<div className='analysis-data-left'>End Date:</div>
							<div className='analysis-data-right'>{this.state.endDate}</div>
						</div>
					</div>
				</div>
				<div className='analysis-data-bottom'>
					<div style={{ marginBottom: '5%' }}>
						For more information, send us an email:{' '}
						<a href='mailto:support@oddsonline.com'>support@oddsonline.com</a>
					</div>
					<Button
						variant='outlined'
						className='control-button'
						onClick={this.downloadSpreadsheet}
						style={{
							color: '#00A82C',
							borderColor: '#00A82C',
							height: '2em',
							margin: 'auto',
						}}
					>
						Download Spreadsheet
					</Button>
				</div>
			</div>
		);
	}
}

export default AnalysisPanel;
